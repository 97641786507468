<template>
  <div class="intervention_apply">
    <div>
      <span>介入编号：</span>
      <span>{{ value.interveneInfo.interveneId }}</span>
    </div>
    <div>
      <span>退款金额：</span>
      <span>￥{{ value.applyRefundFee }}</span>
    </div>
    <div>
      <span>介入理由：</span>
      <span>{{ value.interveneInfo.reasonName }}</span>
    </div>
    <div>
      <span>货物状态：</span>
      <span v-if="+value.interveneInfo.deliverStatus === 1">未收到货</span>
      <span v-if="+value.interveneInfo.deliverStatus === 2">已收到货</span>
    </div>
    <div>
      <span>补充描述：</span>
      <span>{{ value.interveneInfo.description }}</span>
    </div>
    <div>
      <el-image
        v-for="(item, i) in value.interveneInfo.imageUrlList"
        :key="i"
        style="width: 100px; height: 100px; margin-right: 10px"
        fit="contain"
        :src="item"
        :preview-src-list="value.interveneInfo.imageUrlList"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // value: {
      //     imageUrlList: ['https://wsnbh.oss-cn-hangzhou.aliyuncs.com/test/c2c29c44a1dd6010dc121fb20f3a6a36.jpg',
      //     'https://wsnbh-img.hzanchu.com/acimg/d2c68e785d0277ef16876d69a10ff224.png'],
      //     interveneId: 12313,
      //     reason: '发射点发射点发射点发生发射点发射点反对',
      //     deliverStatus: 1, //1、未收到货 2、已收到货
      //     description: '发射点发射点发射点发生发射点发射点反对'
      //   }
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
.intervention_apply {
  font-size: 14px;
  > div {
    line-height: 30px;
  }
}
</style>
