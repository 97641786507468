var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content_header_laft_time" },
    [
      [1, 3, 4, 11, 12, 13, 14].includes(+_vm.refundInfo.refundStatus)
        ? _c("CountDown", {
            attrs: {
              time: _vm.timeView(_vm.refundInfo),
              format: _vm.formatName,
            },
            on: { change: _vm.timeChange },
          })
        : _vm._e(),
      [5, 6].includes(+_vm.refundInfo.refundStatus)
        ? _c("span", [
            _vm._v(
              " " + _vm._s(_vm.timeFormat(_vm.refundInfo.updateTime)) + " "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }