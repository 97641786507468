<template>
  <el-dialog
    title="修改申请"
    :visible.sync="show"
    width="700px"
    :before-close="closeDialog"
    @open="openDialog"
    @closed="onClosed"
  >
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="130px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="举证方："
        prop="proofObject"
      >
        <el-select
          v-model="ruleForm.proofObject"
          style="width: 350px"
          placeholder="请选择举证方"
          size="mini"
          clearable
        >
          <el-option
            label="商家"
            :value="2"
          />
          <el-option
            label="买家"
            :value="1"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="补充举证内容："
        prop="replenishType"
      >
        <el-select
          v-model="ruleForm.replenishType"
          style="width: 350px"
          placeholder="请选择补充举证内容"
          size="mini"
          clearable
        >
          <el-option
            v-for="(item, i) in replenishTypeList"
            :key="i"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="举证限时："
        prop="limitDay"
      >
        <el-select
          v-model="ruleForm.limitDay"
          style="width: 350px"
          placeholder="请选择举证限时"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in Object.keys(LIMITDAY_MAP)"
            :key="item"
            :label="LIMITDAY_MAP[item]"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="备注："
        prop="description"
      >
        <el-input
          v-model="ruleForm.description"
          class="w_350"
          size="mini"
          maxlength="300"
          show-word-limit
          placeholder="请输入备注"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="closeDialog"
      >
        取 消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="onSubmit"
      >
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { LIMITDAY_MAP } from '../../normals.js';

export default {
  name: '',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    refundInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      LIMITDAY_MAP,
      ruleForm: {
        proofObject: 2,
        replenishType: '',
        limitDay: '1',
      },
      replenishTypeList: [],
      rules: {
        proofObject: [
          { required: true, message: '请选择举证方', trigger: 'change' },
        ],
        replenishType: [
          { required: true, message: '请选择补充举证内容', trigger: 'change' },
        ],
        limitDay: [
          { required: true, message: '请选择举证限时', trigger: 'change' },
        ],
      },
    };
  },
  computed: {

  },
  watch: {
  },
  created() {},
  mounted() {

  },
  methods: {
    onClosed() {
      this.ruleForm = {
        proofObject: 2,
        replenishType: '',
        limitDay: '1',
      }
    },
    openDialog() {
      this.$axios.get(this.$api.refund.replenishType).then((row) => {
        this.replenishTypeList = row.data;
      });
    },
    closeDialog() {
      this.$emit('update:show', false);
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm('是否确提交？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$emit('onSubmit', this.ruleForm);
          }).catch(() => {});
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
