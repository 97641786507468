<template>
  <el-dialog
    title="修改申请"
    :visible.sync="show"
    width="700px"
    :before-close="closeDialog"
    @open="openDialog"
    @closed="onClosed"
  >
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="售后类型："
        prop="refundType"
      >
        <el-select
          v-model="ruleForm.refundType"
          style="width: 350px"
          placeholder="请选择售后类型"
          size="mini"
          clearable
          disabled
        >
          <el-option
            v-for="item in Object.keys(REFUND_TYPE_MAP)"
            :key="item"
            :label="REFUND_TYPE_MAP[item]"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="+ruleForm.refundType !== 3 && refundInfo.shippingTime"
        label="货物状态："
        prop="deliverStatus"
      >
        <el-select
          v-model="ruleForm.deliverStatus"
          style="width: 350px"
          placeholder="请选择货物状态"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in Object.keys(DELIVER_STATUS_MAP)"
            :key="item"
            :label="DELIVER_STATUS_MAP[item]"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="退款金额："
        prop="applyRefundFee"
      >
        <el-input-number
          v-model="ruleForm.applyRefundFee"
          style="width: 250px;"
          controls-position="right"
          :min="0"
          :max="Number(refundInfo.membershipCardFee) + Number(refundInfo.payment)"
          placeholder="请输入退款金额"
          size="mini"
          :controls="false"
        />
        <span>&nbsp;元</span>
        <div style="color: #9d9d9d;line-height: 16px">
          可修改，最多¥{{ Number(refundInfo.membershipCardFee) + Number(refundInfo.payment) }}
          <span v-if="refundInfo.postFee > 0">（包含运费¥{{ refundInfo.postFee }}）</span>
        </div>
      </el-form-item>
      <el-form-item
        label="退款原因："
        prop="reason"
      >
        <el-select
          v-model="ruleForm.reason"
          style="width: 350px"
          placeholder="请选择退款原因"
          size="mini"
          clearable
        >
          <el-option
            v-for="(item, i) in refundReasonList"
            :key="i"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="补充描述："
        prop="description"
      >
        <el-input
          v-model="ruleForm.description"
          class="w_350"
          size="mini"
          maxlength="300"
          show-word-limit
          placeholder="请输入补充描述"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="closeDialog"
      >
        取 消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="onSubmit"
      >
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { REFUND_TYPE_MAP, DELIVER_STATUS_MAP } from '../../normals.js';

export default {
  name: '',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    refundInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      REFUND_TYPE_MAP,
      DELIVER_STATUS_MAP,
      ruleForm: {
        refundType: '',
        deliverStatus: '',
        applyRefundFee: '',
        reason: '',
        description: '',
      },
      refundReasonList: [],
      rules: {
        refundType: [
          { required: true, message: '请选择售后类型', trigger: 'change' },
        ],
        deliverStatus: [
          { required: true, message: '请选择货物状态', trigger: 'change' },
        ],
        applyRefundFee: [
          { required: true, message: '请输入退款金额', trigger: 'blur' },
        ],
        reason: [
          { required: true, message: '请选择退款原因', trigger: 'change' },
        ],
      },
    };
  },
  computed: {

  },
  watch: {
  },
  created() {},
  mounted() {

  },
  methods: {
    onClosed() {
      this.ruleForm = {
        refundType: '',
        deliverStatus: '',
        applyRefundFee: '',
        reason: '',
        description: '',
      };
    },
    openDialog() {
      let {
        refundType, deliverStatus, applyRefundFee, reason,
      } = this.refundInfo;
      this.ruleForm.refundType = String(refundType);
      this.ruleForm.deliverStatus = deliverStatus === 0 ? '' : String(deliverStatus);
      this.ruleForm.applyRefundFee = applyRefundFee;
      this.ruleForm.reason = Number(reason);

      this.getRefundReasonList();
    },
    getRefundReasonList() {
      // 1、仅退款 2、退货退款 3、民宿退款 4、退货退款-退货纠纷
      // 先判断是否进入介入状态；非介入状态：1，2，3；介入状态：1，4，3；
      let { refundType } = this.refundInfo;
      this.$axios.get(this.$api.refund.refundreason, {
        params: {
          refundReasonType: +refundType === 2 ? 5 : refundType,
        },
      }).then((row) => {
        this.refundReasonList = row.data;
      });
    },
    closeDialog() {
      this.$emit('update:show', false);
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm('是否确提交？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$emit('onSubmit', this.ruleForm);
          }).catch(() => {});
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
