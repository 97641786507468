var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "el-card",
        { staticClass: "refund-card", attrs: { shadow: "always" } },
        [
          _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  staticClass: "refund-history-tab",
                  on: { "tab-click": _vm.tabClick },
                  model: {
                    value: _vm.tabActive,
                    callback: function ($$v) {
                      _vm.tabActive = $$v
                    },
                    expression: "tabActive",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "1" } },
                    [
                      _c(
                        "el-badge",
                        { attrs: { slot: "label" }, slot: "label" },
                        [_vm._v(" 协商详情 ")]
                      ),
                    ],
                    1
                  ),
                  _vm.refundInfo.interveneInfo
                    ? _c(
                        "el-tab-pane",
                        { attrs: { name: "2" } },
                        [
                          _c(
                            "el-badge",
                            {
                              attrs: {
                                slot: "label",
                                value: _vm.refundInfo.showRed,
                              },
                              slot: "label",
                            },
                            [_vm._v(" 介入举证 ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            _vm._l(_vm.list, function (consult) {
              return _c("div", { key: consult.id, staticClass: "consult" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", { attrs: { src: consult.headPic } }),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "content_heaer" }, [
                    _c(
                      "div",
                      [
                        _vm.roleListDto[consult.consultObject]
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.roleListDto[consult.consultObject]
                                    .type,
                                  size: "mini",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.roleListDto[consult.consultObject]
                                        .name
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" " + _vm._s(consult.consultTitle) + " "),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "time" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .dayjs(consult.consultTime)
                              .format("YYYY-MM-DD HH:mm:ss")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("p", { staticClass: "description" }, [
                    _vm._v(" " + _vm._s(consult.consultDetail) + " "),
                  ]),
                  _c(
                    "div",
                    _vm._l(consult.imageUrlsList, function (image) {
                      return _c("el-image", {
                        key: image,
                        staticStyle: {
                          width: "100px",
                          height: "100px",
                          "margin-right": "10px",
                        },
                        attrs: {
                          fit: "contain",
                          src: image,
                          "preview-src-list": consult.imageUrlsList,
                        },
                      })
                    }),
                    1
                  ),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }