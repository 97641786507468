<template>
  <el-dialog
    :title="Number(refuseType) === 1 ? '拒绝退款' : '拒绝退货'"
    :visible.sync="show"
    width="700px"
    :before-close="closeDialog"
    @open="getRefuseReasonList"
  >
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="拒绝理由："
        prop="reason"
      >
        <el-select
          v-model="ruleForm.reason"
          style="width: 350px"
          placeholder="请选择拒绝理由"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in refuseReasonList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="补充描述："
        prop="description"
      >
        <el-input
          v-model="ruleForm.description"
          class="w_350"
          size="mini"
          maxlength="300"
          show-word-limit
          placeholder="请输入补充描述"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
        />
      </el-form-item>
      <el-form-item
        prop="imageUrlsList"
        label="上传凭证："
        :rules="[
          { type: 'array', required: !!myDescription, message: '请上传凭证', trigger: 'change',}
        ]"
      >
        <UpImage
          :image-list="ruleForm.imageUrlsList"
          :num="10"
        />
        <div style="color: red">{{myDescription}}</div>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="closeDialog">
        取 消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="onSubmit"
      >
        {{ Number(refuseType) === 1 ? '拒绝退款' : '拒绝退货' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import UpImage from '@/components/common/upImage/index';
export default {
  name: '',
  components: {
    UpImage
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    // 1，退款；2：退货
    refuseType: {
      type: [String, Number],
      default: 1,
    },
    // 拒绝理由枚举-1、仅退款 2、退货退款-待卖家处理 3、退货退款-待卖家退款, 4、民宿
    refuseReasonType: {
      type: [String, Number],
      default: 1,
    }
  },
  data() {
    return {
      ruleForm: {
        reason: '',
        description: '',
        imageUrlsList: []
      },
      refuseReasonList: [],
      rules: {
        reason: [
          { required: true, message: '请选择拒绝理由', trigger: 'change' },
        ],
        description: [
          { required: true, message: '请输入补充描述', trigger: 'blur' },
        ],
      }
    }
  },
  computed: {
    myDescription() {
      if (this.ruleForm.reason && this.refuseReasonList.length > 0) {
        const find = this.refuseReasonList.find(item => +item.value === +this.ruleForm.reason);
        return find.description
      } else {
        return ''
      }
    }
  },
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    closeDialog() {
      this.$emit('update:show', false)
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm('是否确提交？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$emit('onSubmit', this.ruleForm)
          }).catch(() => {})
        }
      })
    },
    getRefuseReasonList() {
      this.$axios.get(this.$api.refund.refuseReason, {
        params: {
          refuseReasonType: this.refuseReasonType
        }
      }).then(row => {
        if (row.code === 0) {
          this.refuseReasonList = row.data;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
