<template>
  <div class="refund_detail_content">
    <el-row :gutter="10">
      <el-col :span="12">
        <!-- 售后详情 -->
        <CardDetail
          :app-name="appName"
          :refund-info="refundInfo"
        />
        <div class="button-container">
          <el-button
            type="primary"
            size="small"
            @click="goBack"
          >
            返回列表
          </el-button>
        </div>
      </el-col>
      <el-col :span="12">
        <!-- 售后状态 -->
        <CardRefundState
          ref="cardRefundRef"
          :app-name="appName"
          :refund-info="refundInfo"
          @getRefundDetail="getRefundDetail"
          @getConsult="getConsult"
          @getProofList="getProofList"
        />
        <!-- 介入状态 && [2,5,6].indexOf(+refundInfo.refundStatus) === -1-->
        <CardInterveneState
          v-if="refundInfo.interveneInfo"
          ref="cardInterveneRef"
          :app-name="appName"
          :refund-info="refundInfo"
          @getRefundDetail="getRefundDetail"
          @getConsult="getConsult"
          @getProofList="getProofList"
        />
        <!-- 协商详情/介入举证 -->
        <CardHistory
          :app-name="appName"
          :refund-info="refundInfo"
          :consults="consults"
          :proof-list="proofList"
          @tabClick="tabClick"
        />
      </el-col>
    </el-row>

    <el-backtop target=".app-main" />
  </div>
</template>

<script>
import './refund-style.scss';
import CardDetail from './components/card-detail';
import CardRefundState from './components/card-refund-state';
import CardInterveneState from './components/card-intervene-state';
import CardHistory from './components/card-history';

export default {
  name: 'refund-detail',
  components: {
    CardDetail,
    CardRefundState,
    CardInterveneState,
    CardHistory,
  },
  data() {
    return {
      appName: localStorage.getItem('appName'),
      refundInfo: {},
      consults: [],
      proofList: [],
    };
  },
  computed: {
    refundId() {
      return this.$route.query.refundId;
    },
  },
  mounted() {
    this.getRefundDetail(); // 售后详情
    // this.getRefundGoods()
    this.getConsult(); // 协商列表
  },
  methods: {
    getRefundDetail() {
      this.refundId && this.$axios.get(`${this.$api.refund.refundDetail}?refundId=${this.refundId}`)
        .then((res) => {
          this.refundInfo = res.data;
        });
    },
    getRefundGoods() {
      this.refundId && this.$axios.get(`${this.$api.refund.refundGoods}?refundId=${this.refundId}`)
        .then((res) => {
          this.goodsName = res.data.goodsName;
          this.goodsNum = res.data.goodsNum;
          this.imageUrls = res.data.imageUrls;
        });
    },
    getConsult() {
      this.refundId && this.$axios.get(this.$api.refund.refundConsult, {
        params: {
          refundId: this.refundId,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.consults = res.data;
          this.consults.sort((a, b) => {
            return b.id - a.id;
          });
        }
      });
    },
    // 获取举证tab切换
    tabClick(e) {
      if (+e === 1) {
        this.getConsult();
      } else if (+e === 2) {
        this.getProofList();
      }
    },
    // 获取举证列表
    getProofList() {
      this.refundId && this.$axios.get(this.$api.refund.interveneDetailList, {
        params: {
          refundId: this.refundId,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.proofList = res.data;
        }
      });
    },
    goBack() {
      this.$router.push('/nb/order/refund/list');
    },
  },
};
</script>
<style  lang="scss">
.refund-card {
  .el-card__header {
    background-color: #f5f7fa;
  }
}
</style>

<style lang="scss" scoped>
</style>
