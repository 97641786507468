var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改申请",
        visible: _vm.show,
        width: "700px",
        "before-close": _vm.closeDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "售后类型：", prop: "refundType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "350px" },
                  attrs: {
                    placeholder: "请选择售后类型",
                    size: "mini",
                    clearable: "",
                    disabled: "",
                  },
                  model: {
                    value: _vm.ruleForm.refundType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "refundType", $$v)
                    },
                    expression: "ruleForm.refundType",
                  },
                },
                _vm._l(Object.keys(_vm.REFUND_TYPE_MAP), function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: _vm.REFUND_TYPE_MAP[item], value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          +_vm.ruleForm.refundType !== 3 && _vm.refundInfo.shippingTime
            ? _c(
                "el-form-item",
                { attrs: { label: "货物状态：", prop: "deliverStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "350px" },
                      attrs: {
                        placeholder: "请选择货物状态",
                        size: "mini",
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.deliverStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "deliverStatus", $$v)
                        },
                        expression: "ruleForm.deliverStatus",
                      },
                    },
                    _vm._l(
                      Object.keys(_vm.DELIVER_STATUS_MAP),
                      function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: {
                            label: _vm.DELIVER_STATUS_MAP[item],
                            value: item,
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "退款金额：", prop: "applyRefundFee" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "250px" },
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max:
                    Number(_vm.refundInfo.membershipCardFee) +
                    Number(_vm.refundInfo.payment),
                  placeholder: "请输入退款金额",
                  size: "mini",
                  controls: false,
                },
                model: {
                  value: _vm.ruleForm.applyRefundFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "applyRefundFee", $$v)
                  },
                  expression: "ruleForm.applyRefundFee",
                },
              }),
              _c("span", [_vm._v(" 元")]),
              _c(
                "div",
                { staticStyle: { color: "#9d9d9d", "line-height": "16px" } },
                [
                  _vm._v(
                    " 可修改，最多¥" +
                      _vm._s(
                        Number(_vm.refundInfo.membershipCardFee) +
                          Number(_vm.refundInfo.payment)
                      ) +
                      " "
                  ),
                  _vm.refundInfo.postFee > 0
                    ? _c("span", [
                        _vm._v(
                          "（包含运费¥" + _vm._s(_vm.refundInfo.postFee) + "）"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "退款原因：", prop: "reason" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "350px" },
                  attrs: {
                    placeholder: "请选择退款原因",
                    size: "mini",
                    clearable: "",
                  },
                  model: {
                    value: _vm.ruleForm.reason,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "reason", $$v)
                    },
                    expression: "ruleForm.reason",
                  },
                },
                _vm._l(_vm.refundReasonList, function (item, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "补充描述：", prop: "description" } },
            [
              _c("el-input", {
                staticClass: "w_350",
                attrs: {
                  size: "mini",
                  maxlength: "300",
                  "show-word-limit": "",
                  placeholder: "请输入补充描述",
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 6 },
                },
                model: {
                  value: _vm.ruleForm.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "description", $$v)
                  },
                  expression: "ruleForm.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.closeDialog } },
            [_vm._v(" 取 消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }