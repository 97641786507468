<template>
  <el-dialog
    title="上传举证"
    :visible.sync="show"
    width="700px"
    :before-close="closeDialog"
    @closed="onClosed"
  >
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="补充描述："
        prop="description"
      >
        <el-input
          v-model="ruleForm.description"
          class="w_350"
          size="mini"
          maxlength="300"
          show-word-limit
          placeholder="请输入补充描述"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
        />
      </el-form-item>
      <el-form-item
        prop="imageUrlsList"
        label="上传凭证："
        :rules="[
          { type: 'array', required: refundInfo.interveneInfo ? refundInfo.interveneInfo.replenishSupplier === 1 : false, message: '请上传凭证', trigger: 'change',}
        ]"
      >
        <UpImage
          :image-list="ruleForm.imageUrlsList"
          :num="10"
        />
        <div
          v-if="+refundInfo.interveneInfo.replenishSupplier === 1"
          style="color: red"
        >
          请补充举证：{{ refundInfo.interveneInfo.replenishTypeSupplierName }}
        </div>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="closeDialog"
      >
        取 消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="onSubmit"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import UpImage from '@/components/common/upImage/index';

export default {
  name: '',
  components: {
    UpImage,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    refundInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {
        description: '',
        imageUrlsList: [],
      },
      rules: {
        description: [
          { required: true, message: '请输入补充描述', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {

  },
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    onClosed() {
      this.ruleForm = {
        description: '',
        imageUrlsList: [],
      };
    },
    closeDialog() {
      this.$emit('update:show', false);
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm('是否确提交？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$emit('onSubmit', this.ruleForm);
          }).catch(() => {});
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
