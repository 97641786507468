var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "提醒",
        visible: _vm.changeSaleDialogVisible,
        width: "90%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.changeSaleDialogVisible = $event
        },
        open: _vm.openTheDialog,
      },
    },
    [
      _c("el-alert", {
        attrs: {
          description:
            "该商品参与了加价换购活动（购买指定商品可加价换购商品活动），请谨慎处理退款申请，买家已购的活动指定商品及换购商品，详情如下：",
          title: "提醒：",
          type: "warning",
          "show-icon": "",
          closable: false,
        },
      }),
      _c("div", { staticClass: "table_title" }, [_vm._v("指定商品")]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "element-loading-text": "拼命加载中",
            data: _vm.mainExchangeOrderGoodsList,
            border: "",
            "max-height": "300",
            size: "small",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "订单编号", width: "210" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(" " + _vm._s(row.orderNo) + " "),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: {
                          type: "text",
                          size: "mini",
                          "data-clipboard-text": row.orderNo,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.copy()
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "subOrderStatus", label: "订单状态" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsId", label: "商品ID", width: "70" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsName", label: "商品名称", "min-width": "210" },
          }),
          _c("el-table-column", { attrs: { prop: "skuName", label: "规格" } }),
          _c("el-table-column", {
            attrs: { prop: "sellPrice", label: "售价(元)" },
          }),
          _c("el-table-column", {
            attrs: { prop: "promPrice", label: "备注" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.goodsId == _vm.goodsId ? "当前售后商品" : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-divider"),
      _c("div", { staticClass: "table_title" }, [_vm._v("加购换购商品")]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "element-loading-text": "拼命加载中",
            data: _vm.relationExchangeOrderGoodsList,
            border: "",
            "max-height": "300",
            size: "small",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "订单编号", width: "210" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(" " + _vm._s(row.orderNo) + " "),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: {
                          type: "text",
                          size: "mini",
                          "data-clipboard-text": row.orderNo,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.copy()
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "subOrderStatus", label: "订单状态" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsId", label: "商品ID", width: "70" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsName", label: "商品名称", "min-width": "210" },
          }),
          _c("el-table-column", { attrs: { prop: "skuName", label: "规格" } }),
          _c("el-table-column", {
            attrs: { prop: "sellPrice", label: "售价(元)" },
          }),
          _c("el-table-column", {
            attrs: { prop: "promPrice", label: "活动价(元)" },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.changeSaleDialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("知道了，继续退款")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }