var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "el-card",
        { staticClass: "refund-card", attrs: { shadow: "always" } },
        [
          _c(
            "div",
            {
              staticClass: "header",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [
                _vm._v("介入状态 "),
                _c("span", { staticStyle: { opacity: "0" } }, [
                  _vm._v(_vm._s(_vm.refundInfo.interveneStatus)),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "content_header" }, [
              _c(
                "div",
                { staticClass: "content_header_laft" },
                [
                  _c("p", { staticClass: "content_header_laft_name" }, [
                    _vm._v(
                      " " + _vm._s(_vm.refundInfo.interveneStatusName) + " "
                    ),
                  ]),
                  [4, 14].includes(+_vm.refundInfo.refundStatus)
                    ? [
                        (_vm.refundInfo.interveneInfo.replenishSupplier === 1 &&
                          _vm.appName === "gys") ||
                        _vm.refundInfo.interveneStatus === 1
                          ? _c("TimeView", {
                              attrs: {
                                "app-name": _vm.appName,
                                "refund-info": _vm.refundInfo,
                              },
                              on: {
                                getRefundDetail: function ($event) {
                                  return _vm.$emit("getRefundDetail")
                                },
                                getConsult: _vm.getConsult,
                                getProofList: _vm.getProofList,
                              },
                            })
                          : _vm._e(),
                        _vm.refundInfo.interveneInfo.replenishSupplier === -1 &&
                        _vm.appName === "gys"
                          ? _c("span", [_vm._v(" （举证超时） ")])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "header_btn" },
                [
                  _vm.appName === "gys" &&
                  (+_vm.refundInfo.interveneStatus === 1 ||
                    +_vm.refundInfo.interveneStatus === 2)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.showProofUploading = true
                            },
                          },
                        },
                        [_vm._v(" 上传举证 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm.appName === "admin"
              ? _c(
                  "div",
                  {
                    staticClass: "content_money",
                    staticStyle: { "margin-right": "10px" },
                  },
                  [
                    _vm.refundInfo.interveneInfo.replenishSupplier
                      ? _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          [
                            _vm._v(" 待商家举证中 "),
                            _vm.refundInfo.interveneInfo.replenishSupplier === 1
                              ? _c("TimeView", {
                                  attrs: {
                                    identity: "supplier",
                                    "app-name": _vm.appName,
                                    "refund-info": _vm.refundInfo,
                                  },
                                  on: {
                                    getRefundDetail: function ($event) {
                                      return _vm.$emit("getRefundDetail")
                                    },
                                    getConsult: _vm.getConsult,
                                    getProofList: _vm.getProofList,
                                  },
                                })
                              : _vm._e(),
                            _vm.refundInfo.interveneInfo.replenishSupplier ===
                            -1
                              ? _c("span", [_vm._v("（已超时）")])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.refundInfo.interveneInfo.replenishConsumer
                      ? _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          [
                            _vm._v(" 待买家举证中 "),
                            _vm.refundInfo.interveneInfo.replenishConsumer === 1
                              ? _c("TimeView", {
                                  attrs: {
                                    identity: "user",
                                    "app-name": _vm.appName,
                                    "refund-info": _vm.refundInfo,
                                  },
                                  on: {
                                    getRefundDetail: function ($event) {
                                      return _vm.$emit("getRefundDetail")
                                    },
                                    getConsult: _vm.getConsult,
                                    getProofList: _vm.getProofList,
                                  },
                                })
                              : _vm._e(),
                            _vm.refundInfo.interveneInfo.replenishConsumer ===
                            -1
                              ? _c("span", [_vm._v("（已超时）")])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.appName === "gys" &&
            _vm.refundInfo.interveneInfo.replenishSupplier === 1
              ? _c(
                  "div",
                  {
                    staticClass: "content_money",
                    staticStyle: { color: "red" },
                  },
                  [
                    _vm._v(
                      " 请补充举证：" +
                        _vm._s(
                          _vm.refundInfo.interveneInfo.replenishTypeSupplierName
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _c("ProofUploading", {
        attrs: { "refund-info": _vm.refundInfo, show: _vm.showProofUploading },
        on: {
          "update:show": function ($event) {
            _vm.showProofUploading = $event
          },
          onSubmit: _vm.onSubmitProofUploading,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }