var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改申请",
        visible: _vm.show,
        width: "700px",
        "before-close": _vm.closeDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "举证方：", prop: "proofObject" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "350px" },
                  attrs: {
                    placeholder: "请选择举证方",
                    size: "mini",
                    clearable: "",
                  },
                  model: {
                    value: _vm.ruleForm.proofObject,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "proofObject", $$v)
                    },
                    expression: "ruleForm.proofObject",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "商家", value: 2 } }),
                  _c("el-option", { attrs: { label: "买家", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "补充举证内容：", prop: "replenishType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "350px" },
                  attrs: {
                    placeholder: "请选择补充举证内容",
                    size: "mini",
                    clearable: "",
                  },
                  model: {
                    value: _vm.ruleForm.replenishType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "replenishType", $$v)
                    },
                    expression: "ruleForm.replenishType",
                  },
                },
                _vm._l(_vm.replenishTypeList, function (item, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "举证限时：", prop: "limitDay" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "350px" },
                  attrs: {
                    placeholder: "请选择举证限时",
                    size: "mini",
                    clearable: "",
                  },
                  model: {
                    value: _vm.ruleForm.limitDay,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "limitDay", $$v)
                    },
                    expression: "ruleForm.limitDay",
                  },
                },
                _vm._l(Object.keys(_vm.LIMITDAY_MAP), function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: _vm.LIMITDAY_MAP[item], value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "description" } },
            [
              _c("el-input", {
                staticClass: "w_350",
                attrs: {
                  size: "mini",
                  maxlength: "300",
                  "show-word-limit": "",
                  placeholder: "请输入备注",
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 6 },
                },
                model: {
                  value: _vm.ruleForm.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "description", $$v)
                  },
                  expression: "ruleForm.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.closeDialog } },
            [_vm._v(" 取 消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }