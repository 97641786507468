var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "上传举证",
        visible: _vm.show,
        width: "700px",
        "before-close": _vm.closeDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "补充描述：", prop: "description" } },
            [
              _c("el-input", {
                staticClass: "w_350",
                attrs: {
                  size: "mini",
                  maxlength: "300",
                  "show-word-limit": "",
                  placeholder: "请输入补充描述",
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 6 },
                },
                model: {
                  value: _vm.ruleForm.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "description", $$v)
                  },
                  expression: "ruleForm.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "imageUrlsList",
                label: "上传凭证：",
                rules: [
                  {
                    type: "array",
                    required: _vm.refundInfo.interveneInfo
                      ? _vm.refundInfo.interveneInfo.replenishSupplier === 1
                      : false,
                    message: "请上传凭证",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("UpImage", {
                attrs: { "image-list": _vm.ruleForm.imageUrlsList, num: 10 },
              }),
              +_vm.refundInfo.interveneInfo.replenishSupplier === 1
                ? _c("div", { staticStyle: { color: "red" } }, [
                    _vm._v(
                      " 请补充举证：" +
                        _vm._s(
                          _vm.refundInfo.interveneInfo.replenishTypeSupplierName
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.closeDialog } },
            [_vm._v(" 取 消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" 提 交 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }