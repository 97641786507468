<template>
  <div>
    <!-- 售后状态 -->
    <el-card
      class="refund-card"
      shadow="always"
    >
      <div
        slot="header"
        class="header"
      >
        <span>售后状态
          <span style="opacity: 0;">{{ refundInfo.refundStatus }}</span>
        </span>
      </div>
      <div class="content">
        <div class="content_header">
          <div class="content_header_laft">
            <!-- 售后状态名字 -->
            <p class="content_header_laft_name">
              {{ refundInfo.refundStatusName }}
              <span
                v-if="Number(refundInfo.refundStatus) === 2 && refundInfo.extraField.thirdErrMsg"
              >
                （{{ refundInfo.extraField.thirdErrMsg }}）
              </span>
            </p>

            <!-- 剩余时间&操作时间 -->
            <TimeView
              v-if="[1,2,3,5,6,11,12,13].includes(+refundInfo.refundStatus)"
              :app-name="appName"
              :refund-info="refundInfo"
              @getRefundDetail="getRefundDetail"
              @getConsult="getConsult"
              @getProofList="getProofList"
            />
          </div>
          <!-- 售后按钮 -->
          <div
            v-loading="loading"
            class="header_btn"
          >
            <!-- 后台隐藏介入详情按钮 16 -->
            <span
              v-for="(item, i) in refundInfo.buttonList.filter(fil => +fil.value !== 16)"
              :key="i"
            >
              <el-button
                style="margin-left: 10px;"
                :type="i === refundInfo.buttonList.filter(fil => +fil.value !== 16).length - 1 ? 'primary' : ''"
                size="mini"
                @click="operation(item)"
              >
                {{ item.name }}
              </el-button>
            </span>
          </div>
        </div>

        <div class="content_money">
          <!-- 退款总金额 -->
          <div v-if="refundInfo.refundStatus !== 7">
            退款金额：￥
            <!-- 退款中&退款成功 -->
            <template v-if="refundInfo.refundStatus === 2 || refundInfo.refundStatus === 5">
              {{ refundInfo.refundFee }}
            </template>
            <!-- 其他 -->
            <template v-else>
              {{ refundInfo.applyRefundFee }}
            </template>
          </div>
          <!-- 会员卡支付流程金额 -->
          <div>
            <!-- 退款中&退款成功 -->
            <template v-if="refundInfo.refundStatus === 2 || refundInfo.refundStatus === 5">
              <template v-if="refundInfo.refundMembershipCardFee > 0 || refundInfo.originRefundFee > 0">
                （
                <template v-if="refundInfo.refundMembershipCardFee > 0">
                  {{ refundInfo?.memberTypeExplain }}：￥ {{ refundInfo.refundMembershipCardFee }}
                </template>
                <!-- <template v-if="refundInfo.refundMembershipCardFee > 0">
                  工会福利：￥ {{ refundInfo.refundMembershipCardFee }}
                </template> -->
                <template v-if="refundInfo.originRefundFee > 0">
                  &nbsp;{{ refundInfo.payName }}：￥ {{ refundInfo.originRefundFee }}
                </template>
                ）
              </template>
            </template>
            <!-- 其他 -->
            <template v-else>
              <template v-if="refundInfo.applyRefundMembershipCardFee > 0 || refundInfo.applyRefundPaymentFee > 0">
                （
                <template v-if="refundInfo.applyRefundMembershipCardFee > 0">
                  {{ refundInfo?.memberTypeExplain }}：￥ {{ refundInfo.applyRefundMembershipCardFee }}
                </template>
                <!-- <template v-if="refundInfo.applyRefundMembershipCardFee > 0">
                  工会福利：￥ {{ refundInfo.applyRefundMembershipCardFee }}
                </template> -->
                <template v-if="refundInfo.applyRefundPaymentFee > 0">
                  &nbsp;{{ refundInfo.payName }}：￥ {{ refundInfo.applyRefundPaymentFee }}
                </template>
                ）
              </template>
            </template>
          </div>
        </div>

        <!-- 物流信息 -->
        <div
          v-if="refundInfo.extraField.logisticName && refundInfo.extraField.logisticNo"
          style="text-align: right"
        >
          <el-link
            type="primary"
            @click="toLogistic"
          >
            {{ refundInfo.extraField.logisticName }}
            {{ refundInfo.extraField.logisticNo }}
          </el-link>
        </div>
      </div>
    </el-card>

    <AddGoodsTable
      ref="addGoodsTableRef"
      :exchange-relate-id="refundInfo.exchangeRelateId"
      :goods-id="refundInfo.goodsId"
      @onSubmit="onSubmitAgreeRefund"
    />

    <!-- 同意退货，选择退货地址弹窗 -->
    <AddressList
      :app-name="appName"
      :show.sync="showAddressList"
      :address-list="addressList"
      @onSubmit="onSubmitSalesReturn"
    />

    <!-- 拒绝退货/拒绝退款 弹窗  -->
    <RefuseDialog
      :show.sync="showRefuseDialog"
      :refuse-type="refuseType"
      :refuse-reason-type="refuseReasonType"
      @onSubmit="onSubmitRefuce"
    />

    <!-- 修改申请 弹窗 -->
    <EditApply
      :show.sync="showEditApply"
      :refund-info="refundInfo"
      @onSubmit="onSubmitEditApply"
    />

    <!-- 补充举证 弹窗 -->
    <EditProof
      :show.sync="showEditProof"
      :refund-info="refundInfo"
      @onSubmit="onSubmitEditProof"
    />
  </div>
</template>

<script>
import TimeView from './timeView.vue';
import AddGoodsTable from './addGoodsTable';
import AddressList from './addressList.vue';
import RefuseDialog from './refuseDialog.vue';
import EditApply from './editApply.vue';
import EditProof from './editProof.vue';

export default {
  name: '',
  components: {
    TimeView,
    AddGoodsTable,
    AddressList,
    RefuseDialog,
    EditApply,
    EditProof,
  },
  props: {
    appName: {
      type: String,
      default: '',
    },
    refundInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      refuseType: 1, //  1，退款；2：退货
      refuseReasonType: 1, // 拒绝理由枚举 1、仅退款2、退货退款-待卖家处理 3、退货退款-待卖家退款，4、民宿退款
      loading: false,

      showAddressList: false, // 显示发货地址列表
      addressList: [], // 发货地址

      showRefuseDialog: false, // 显示拒绝退货/退款

      showEditApply: false, // 显示修改补充举证

      showEditProof: false, // 显示补充举证
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    // 操作
    operation(item) {
      if (item.value === 1) {
        // 运营-开启售后
        this.openRefuse();
      } else if (item.value === 3) {
        // 运营修改售后申请
        this.showEditApply = true;
      } else if (item.value === 6) {
        // 拒绝退款
        this.refuserRefund();
      } else if (item.value === 7) {
        // 同意退款
        this.passHandle();
      } else if (item.value === 8) {
        // 同意退货
        this.passSalesReturn();
      } else if (item.value === 5) {
        // 拒绝退货
        this.refuseSalesReturn();
      } else if (item.value === 14) {
        // 补充举证
        this.showEditProof = true;
      }
    },
    passHandle() {
      if (this.refundInfo.exchangeMainGoodsOrNot) {
        this.$refs.addGoodsTableRef.changeSaleDialogVisible = true; // 保供需求-提醒弹窗
      } else {
        let title;
        let message;
        let btnText;
        if (this.appName === 'gys') {
          // 商家后台同意退款
          if (this.refundInfo.refundStatus === 1 && this.refundInfo.refundType === 2) {
            // 售后类型：1、仅退款（未发货） 2、仅退款（已发货）3、民宿退款
            title = '确认退款给买家';
            message = `请务必确认退货商品没问题后再操作。同意后，将直接退款给用户${this.refundInfo.applyRefundFee}元。`;
            btnText = '同意退款';
          } else if (this.refundInfo.refundStatus === 12) {
            title = '买家未寄回商品，确认退款？';
            message = `同意后将直接退款给用户${this.refundInfo.applyRefundFee}元，请务必谨慎操作，以免造成损失。`;
            btnText = '同意退款';
          } else {
            title = '同意仅退款';
            message = `同意后，将直接退款给用户${this.refundInfo.applyRefundFee}元`;
            btnText = '同意仅退款';
          }
        } else if (this.appName === 'admin') {
          // 运营后台同意退款
          if (this.refundInfo.refundStatus === 12
          || (this.refundInfo.refundType === 2 && !this.refundInfo.extraField.logisticNo)) {
            title = '申请退货退款，买家未退货，确认退款？';
            message = `同意后，商家将直接退款给用户${this.refundInfo.applyRefundFee}元`;
            btnText = '同意退款';
          } else {
            title = '确认退款给买家';
            message = `同意后，商家将直接退款给用户${this.refundInfo.applyRefundFee}元`;
            btnText = '同意退款';
          }
        }
        this.$confirm(message, title, {
          confirmButtonText: btnText,
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.onSubmitAgreeRefund();
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    getRefundDetail() {
      this.$emit('getRefundDetail');
    },
    getConsult() {
      this.$emit('getConsult');
    },
    getProofList() {
      this.$emit('getProofList');
    },
    // 展示同意退货
    passSalesReturn() {
      this.loading = true;
      this.$axios.get(this.$api.refund.refundAddressList, {
        params: {
          supplierId: this.refundInfo.supplierId,
          refundId: this.refundInfo.refundId,
        },
      }).then((row) => {
        this.loading = false;
        if (row.code === 0) {
          if (row.data && Array.isArray(row.data) && row.data.length > 0) {
            this.addressList = row.data;
            this.showAddressList = true;
          } else {
            let title;
            let message;
            if (this.appName === 'gys') {
              title = '请先设置退货地址';
              message = '仅支持在商家App设置退货地址。路径：我的-退货地址管理';
            } else if (this.appName === 'admin') {
              title = '商家未设置退货地址';
              message = '商家未设置退货地址，无法同意退货，请联系商家设置或直接同意退款';
            }
            this.$alert(message, title, {
              type: 'warning',
              confirmButtonText: '确定',
              callback: () => {},
            });
          }
        }
      });
    },
    // 确认退货
    onSubmitSalesReturn(item) {
      this.loading = true;
      this.$axios.post(this.$api.refund.agreeDeliver, {
        refundAddressId: item.id,
        refundId: this.refundInfo.refundId,
      }).then((row) => {
        this.loading = false;
        if (row.code === 0) {
          this.$message.success('操作成功！');
          this.getRefundDetail();
          this.getConsult();
          this.getProofList();
          this.showAddressList = false;
        }
      });
    },
    // 拒绝退货
    refuseSalesReturn() {
      this.refuseType = 2;
      this.refuseReasonType = 2;
      this.showRefuseDialog = true;
    },
    // 拒绝退款
    refuserRefund() {
      if (this.appName === 'gys') {
        // 商家后台
        this.refuseType = 1;
        // 售后类型：1、仅退款（未发货） 2、仅退款（已发货）3、民宿退款
        // 除了发货退款的，就是仅退款的
        switch (+this.refundInfo.refundType) {
          case 3:
            this.refuseReasonType = 4;
            break;
          case 2:
            this.refuseReasonType = 3;
            break;
          default:
            this.refuseReasonType = 1;
            break;
        }
        this.showRefuseDialog = true;
      } else if (this.appName === 'admin') {
        // 运营后台
        this.$confirm('拒绝退款，会直接关闭用户的售后申请。', '拒绝退款', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.loading = true;
          this.$axios.post(this.$api.refund.refuseRefund, {
            refundId: this.refundInfo.refundId,
          }).then((row) => {
            this.loading = false;
            if (row.code === 0) {
              this.$message.success('操作成功！');
              this.getRefundDetail();
              this.getConsult();
              this.getProofList();
            }
          });
        }).catch(() => {});
      }
    },
    // 提交拒绝退货退款
    onSubmitRefuce(item) {
      let api;
      if (this.refuseType === 1) {
        // 退款
        api = this.$api.refund.refuseRefund;
      } else if (this.refuseType === 2) {
        // 退货
        api = this.$api.refund.refuseDeliver;
      }
      this.loading = true;
      this.$axios.post(api, {
        ...item,
        refundId: this.refundInfo.refundId,
      }).then((row) => {
        this.loading = false;
        if (row.code === 0) {
          this.$message.success('操作成功！');
          this.getRefundDetail();
          this.getConsult();
          this.getProofList();
          this.showRefuseDialog = false;
        }
      });
    },
    // 提交同意退款
    onSubmitAgreeRefund() {
      this.loading = true;
      this.$axios.post(this.$api.refund.agreeRefund, {
        refundId: this.refundInfo.refundId,
        applyRefundFee: this.refundInfo.applyRefundFee,
      }).then((row) => {
        this.loading = false;
        if (row.code === 0) {
          this.$message.success('操作成功！');
          this.getRefundDetail();
          this.getConsult();
          this.getProofList();
        }
      });
    },
    // 确认修改申请售后
    onSubmitEditApply(value) {
      this.loading = true;
      this.$axios.post(this.$api.refund.edit, {
        ...value,
        refundId: this.refundInfo.refundId,
      }).then((row) => {
        this.loading = false;
        if (row.code === 0) {
          this.$message.success('操作成功！');
          this.getRefundDetail();
          this.getConsult();
          this.getProofList();
          this.showEditApply = false;
        }
      });
    },
    // 确定补充举证
    onSubmitEditProof(value) {
      this.loading = true;
      this.$axios.post(this.$api.refund.replenish, {
        ...value,
        refundId: this.refundInfo.refundId,
      }).then((row) => {
        this.loading = false;
        if (row.code === 0) {
          this.$message.success('操作成功！');
          this.getRefundDetail();
          this.getConsult();
          this.getProofList();
          this.showEditProof = false;
        }
      });
    },
    // 物流详情
    toLogistic() {
      this.$router.push({
        path: '/nb/order/refund/track',
        query: {
          logisticNo: this.refundInfo.extraField.logisticNo,
          logisticCode: this.refundInfo.extraField.logisticCode,
          logisticName: this.refundInfo.extraField.logisticName,
        },
      });
    },
    // 运营-开启售后
    openRefuse() {
      this.$confirm('是否开启售后入口？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.loading = true;
        this.$axios.get(this.$api.refund.openRefundShow, {
          params: {
            refundId: this.refundInfo.refundId,
          },
        }).then((row) => {
          this.loading = false;
          if (row.code === 0) {
            this.$message.success('操作成功！');
            this.getRefundDetail();
            this.getConsult();
            this.getProofList();
          }
        });
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
