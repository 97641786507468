<template>
  <div class="">
    <el-card
      class="refund-card"
      shadow="always"
    >
      <div class="content">
        <el-tabs v-model="tabActive">
          <el-tab-pane
            label="售后申请"
            name="1"
          >
            <p>
              <span>售后编码:</span>
              <span>{{ refundInfo.refundNo }}</span>
            </p><p>
              <span>售后类型:</span>
              <span class="red">{{ REFUND_TYPE_MAP[refundInfo.refundType] }}</span>
            </p>
            <p>
              <span>订单状态:</span>
              <span class="red">{{ refundInfo.refundStatusName }}</span>
            </p>
            <p>
              <span>退款金额:</span>
              <span class="red">￥{{ refundInfo.applyRefundFee }}</span>
            </p>
            <p>
              <span>退款原因:</span>
              <span class="red">{{ refundInfo.reasonName }}</span>
            </p>
            <p v-if="refundInfo.reasonDescription">
              <span>退款补充原因:</span>
              <span class="word_break">{{ refundInfo.reasonDescription|| '暂无' }}</span>
            </p>
          </el-tab-pane>
          <el-tab-pane
            v-if="refundInfo.interveneInfo"
            label="介入申请"
            name="2"
          >
            <!-- 介入详情 -->
            <InterveneApply :value="refundInfo" />
          </el-tab-pane>
        </el-tabs>

        <!-- --------------------------------------------- -->
        <el-divider />

        <p>
          <span class="order_number">订单编号:</span>
          <span>{{ refundInfo.orderNo }}</span>
          <el-button
            type="text"
            @click="handleClipboard($event)"
          >
            复制
          </el-button>
        </p>
        <p v-if="refundInfo.groupTime">
          <span>成团时间:</span>
          <span>{{ dayjs(refundInfo.groupTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </p>
        <p>
          <span>订单确认时间:</span>
          <span>{{ dayjs(refundInfo.orderCreateTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </p>
        <!--            <p>-->
        <!--              <span>退货包运费:</span>-->
        <!--              <span></span>-->
        <!--            </p>-->

        <div class="good card">
          <img :src="refundInfo.goodsImage" />
          <div class="text">
            <p>{{ refundInfo.goodsName }}</p>
            <p>规格：{{ refundInfo.skuName }}</p>
            <p v-if="refundInfo.totalFee">
              {{ refundInfo.totalFee }}元优惠券
            </p>
            <p>共{{ refundInfo.sellCount }}件商品</p>
            <p>
              <span v-if="refundInfo.membershipCardFee > 0">{{ refundInfo?.memberTypeExplain }}支付
                :￥{{ refundInfo.membershipCardFee }} </span>
              <!-- <span v-if="refundInfo.membershipCardFee > 0">工会福利支付:￥{{ refundInfo.membershipCardFee }} </span> -->
              实收:￥{{ refundInfo.supplierPayment }}(邮费:{{ refundInfo.postFee }})
            </p>
            <p class="goods_payment">
              金额：￥{{ refundInfo.payment }}
            </p>
          </div>
        </div>

        <div class="card">
          <i class="el-icon-location icon"></i>
          <div class="text">
            <p>
              <span>买家：</span>
              <span>{{ refundInfo.receiverName }}</span>
            </p>
            <p>
              <span>联系电话</span>
              <span>{{ refundInfo.mobile }}</span>
            </p>
            <p>
              <span>收货地址：</span>
              <span>{{ refundInfo.address }}</span>
            </p>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { REFUND_TYPE_MAP } from '../../normals';
import clipboard from '@/utils/clipboard';
import InterveneApply from './interveneApply';

export default {
  name: '',
  components: {
    InterveneApply,
  },
  props: {
    refundInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      REFUND_TYPE_MAP,
      tabActive: '1', // 售后申请tab
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    dayjs,
    handleClipboard(event) {
      clipboard(this.refundInfo.orderNo, event);
    },
  },
};
</script>

<style scoped lang="scss">
.word_break {
  word-break: break-all;
}
</style>
