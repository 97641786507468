<template>
  <el-dialog
    title="提醒"
    :visible.sync="changeSaleDialogVisible"
    width="90%"
    center
    @open="openTheDialog">

    <el-alert
      description="该商品参与了加价换购活动（购买指定商品可加价换购商品活动），请谨慎处理退款申请，买家已购的活动指定商品及换购商品，详情如下："
      title="提醒："
      type="warning"
      show-icon
      :closable="false"
    ></el-alert>

    <div class="table_title">指定商品</div>

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="mainExchangeOrderGoodsList"
      border
      max-height="300"
      size="small"
    >
      <el-table-column
        label="订单编号"
        width="210"
      >
        <template slot-scope="{row}">
          {{row.orderNo}} 
          <el-button 
            class="btn"
            type="text" 
            size="mini" 
            :data-clipboard-text="row.orderNo"
            @click="copy()">复制</el-button>
        </template>
      </el-table-column>

      <el-table-column
        prop="subOrderStatus"
        label="订单状态"
      >
      </el-table-column>
      <el-table-column
        prop="goodsId"
        label="商品ID"
        width="70"
      />
      <el-table-column
        prop="goodsName"
        label="商品名称"
        min-width="210"
      />
      <el-table-column
        prop="skuName"
        label="规格"
      />
      <el-table-column
        prop="sellPrice"
        label="售价(元)"
      />
      <el-table-column
        prop="promPrice"
        label="备注"
      >
        <template slot-scope="{row}">
          {{row.goodsId == goodsId ? '当前售后商品' : ''}}
        </template>
      </el-table-column>
    </el-table>

    <el-divider></el-divider>
    
    <div class="table_title">加购换购商品</div>

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="relationExchangeOrderGoodsList"
      border
      max-height="300"
      size="small"
    >
      <el-table-column
        label="订单编号"
        width="210"
      >
        <template slot-scope="{row}">
          {{row.orderNo}} 
          <el-button 
            class="btn"
            type="text" 
            size="mini" 
            :data-clipboard-text="row.orderNo"
            @click="copy()">复制</el-button>
        </template>
      </el-table-column>

      <el-table-column
        prop="subOrderStatus"
        label="订单状态"
      >
      </el-table-column>
      <el-table-column
        prop="goodsId"
        label="商品ID"
        width="70"
      />
      <el-table-column
        prop="goodsName"
        label="商品名称"
        min-width="210"
      />
      <el-table-column
        prop="skuName"
        label="规格"
      />
      <el-table-column
        prop="sellPrice"
        label="售价(元)"
      />
      <el-table-column
        prop="promPrice"
        label="活动价(元)"
      />
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="changeSaleDialogVisible = false" size="small">取 消</el-button>
      <el-button type="primary" @click="onSubmit" size="small">知道了，继续退款</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  name: '',
  components: {},
  props: {
    exchangeRelateId: {
      type: String,
      default: ''
    },
    goodsId: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      changeSaleDialogVisible: false, //换购弹窗
      mainExchangeOrderGoodsList: [],
      relationExchangeOrderGoodsList: [],
      loading: false,
    }
  },
  computed: {},
  watch: {

  },
  created () {},
  mounted () {
  },
  methods: {
    openTheDialog() {
      this.loading = true
      this.$axios({
        method: 'get',
        url: this.$api.refund.getExchangeOrder,
        params: {exchangeRelateId: this.exchangeRelateId}
      }).then(res => {
        const r = res || {}
        if (r.code === 0) {
          this.mainExchangeOrderGoodsList = r.data.mainExchangeOrderGoodsList;
          this.relationExchangeOrderGoodsList = r.data.relationExchangeOrderGoodsList;
        }
        this.loading = false
      })
    },
    onSubmit() {
      this.changeSaleDialogVisible = false;
      this.$emit('onSubmit')
    },
    // 复制功能
    copy() {
      let clipboard = new Clipboard('.btn')
      clipboard.on('success', e => {
        this.$message.success('复制成功')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message.error('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },
  }
}
</script>

<style scoped lang="scss">
.table_title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}
</style>