<template>
  <el-dialog
    title="选择退货地址"
    :visible.sync="show"
    width="700px"
    :before-close="closeDialog"
  >
    <div>
      <el-radio-group
        v-model="radioDate"
        style="width: 100%"
      >
        <div
          v-for="(item, i) in addressList"
          :key="i"
        >
          <el-radio :label="item">
            <span style="margin-right: 20px">{{ item.receiverName }}</span>
            <span style="margin-right: 20px">{{ item.receiverMobile }}</span>
            <span class="default" v-if="item.isDefault === 1">默认</span>
            <div style="margin-top: 5px">
              {{ item.provinceName }}
              {{ item.cityName }}
              {{ item.districtName }}
            </div>
            <div style="white-space: normal;margin-top: 5px">{{ item.address }}</div>
          </el-radio>
          <el-divider></el-divider>
        </div>
      </el-radio-group>

      <div style="color: red">新增地址，请打开商家App操作，路径：我的-退货地址管理</div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="closeDialog">
        取 消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="onSubmit"
      >
        同意退货
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    appName: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    addressList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      radioDate: null
    }
  },
  computed: {},
  watch: {
    addressList: {
      handler(val) {
        this.radioDate = val.find(item => item.isDefault === 1);
      }
    }
  },
  created() {},
  mounted() {
  },
  methods: {
    closeDialog() {
      this.$emit('update:show', false)
    },
    onSubmit() {
      if (!this.radioDate) {
        this.$message.warning('请选择退货地址');
        return;
      }
      let title;
      let message;
      if (this.appName === 'gys') {
        title = '同意退货';
        message = '同意申请后，售后单会变为【待买家退货】';
      } else if (this.appName === 'admin') {
        title = '同意退货';
        message = '同意申请后，售后单会变为【待买家退货】';
      }
      this.$confirm(message, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('onSubmit', this.radioDate)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.default {
  border: 1px solid #ccc;
  color: #ccc;
  border-radius: 3px;
  padding: 0 3px;
}
</style>
