<template>
  <div class="content_header_laft_time">
    <!-- 剩余时间 timeView(refundInfo) -->
    <CountDown
      v-if="[1,3,4,11,12,13,14].includes(+refundInfo.refundStatus)"
      :time="timeView(refundInfo)"
      :format="formatName"
      @change="timeChange"
    />
    <!-- 操作时间 -->
    <span v-if="[5,6].includes(+refundInfo.refundStatus)">
      {{ timeFormat(refundInfo.updateTime) }}
    </span>
  </div>
</template>

<script>
import dayjs from 'dayjs';
// import CountDown from '@/components/CountDown/index.vue';
import { CountDown } from 'vue-countdown-simple';

export default {
  name: '',
  components: {
    CountDown,
  },
  props: {
    identity: {
      type: String,
      default: 'supplier',
    },
    appName: {
      type: String,
      default: '',
    },
    refundInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formatName: '',
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    if (this.timeView(this.refundInfo) < 1000 * 60 * 60 * 24) {
      this.formatName = '剩余HH小时MM分SS秒';
    } else {
      this.formatName = '剩余DD天HH小时MM分SS秒';
    }
  },
  methods: {
    timeView(refundInfo) {
      // 2: '退款中',
      // 5: '退款成功',
      // 6: '退款关闭',
      const getTimes = (time = 0, num = 0) => {
        return Number(num) - (refundInfo.extraField.currentSystemTime - Number(time));
      };
      if (refundInfo.refundStatus === 1) {
        // 1: '待商家退款',
        // refundInfo.refundType 售后类型：1、仅退款（未发货） 2、仅退款（已发货）3、民宿退款
        if (+refundInfo.refundType === 2) {
          return getTimes(refundInfo.extraField.refundLogicTime, refundInfo.extraField.refundLogicTimeLimit);
        }
        return getTimes(refundInfo.extraField.applyTime, refundInfo.extraField.applyTimeLimit);
      } if (refundInfo.refundStatus === 3) {
        // 3: '(商家拒绝退款)待用户处理',
        return getTimes(refundInfo.extraField.refuseRefundTime, refundInfo.extraField.refuseRefundTimeLimit);
      } if (refundInfo.refundStatus === 11) {
        // 请处理退货申请
        return getTimes(refundInfo.extraField.applyTime, refundInfo.extraField.applyTimeLimit);
      } if (refundInfo.refundStatus === 12) {
        // 12: '待买家退货
        return getTimes(refundInfo.extraField.agreeReturnGoodsTime, refundInfo.extraField.agreeReturnGoodsTimeLimit);
      } if (refundInfo.refundStatus === 13) {
        // 3: '(商家拒绝退货)待用户处理',
        return getTimes(refundInfo.extraField.refuseRefundDeliveryTime, refundInfo.extraField.refuseRefundDeliveryTimeLimit);
      } if (refundInfo.refundStatus === 4 || refundInfo.refundStatus === 14) {
        // 4: '平台介入中（拒绝退款）',
        // 14  介入中（拒绝退货）
        // 商家是否需要补充举证 0否1是 -1举证超时
        if (+refundInfo.interveneInfo.replenishSupplier === 1 && this.identity === 'supplier') {
          return getTimes(refundInfo.extraField.supplierReplenishTime, refundInfo.extraField.supplierReplenishTimeLimit);
        }
        // 买家举证时间
        if (+refundInfo.interveneInfo.replenishConsumer === 1 && this.identity === 'user') {
          return getTimes(refundInfo.extraField.consumerReplenishTime, refundInfo.extraField.consumerReplenishTimeLimit);
        }
        // 协商中
        if (+refundInfo.interveneStatus === 1) {
          return getTimes(refundInfo.extraField.interveneTime, refundInfo.extraField.interveneTimeLimit);
        }
      }
    },
    timeFormat(time) {
      return time ? dayjs(+time).format('YYYY年MM月DD日 HH:mm:ss') : '';
    },
    timeChange(e) {
      if (e
        && e.days === 0
        && e.hours === 0
        && e.minutes === 0
        && e.seconds === 0
        && e.milliseconds > 0) {
        this.$alert('剩余时间已结束，系统已自动处理', '提示', {
          type: 'warning',
          confirmButtonText: '确定',
          callback: () => {
            this.$emit('getRefundDetail');
            this.$emit('getConsult');
            this.$emit('getProofList');
            // this.$router.go(0);
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content_header_laft_time {
  font-size: 15px;
}
</style>
