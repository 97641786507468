<template>
  <div class="">
    <el-card
      class="refund-card"
      shadow="always"
    >
      <div>
        <el-tabs
          v-model="tabActive"
          class="refund-history-tab"
          @tab-click="tabClick"
        >
          <el-tab-pane
            name="1"
          >
            <el-badge slot="label">
              协商详情
            </el-badge>
          </el-tab-pane>
          <el-tab-pane
            v-if="refundInfo.interveneInfo"
            name="2"
          >
            <el-badge
              slot="label"
              :value="refundInfo.showRed"
            >
              介入举证
            </el-badge>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div>
        <div
          v-for="consult in list"
          :key="consult.id"
          class="consult"
        >
          <div class="icon">
            <img :src="consult.headPic" />
          </div>
          <div class="content">
            <div class="content_heaer">
              <div>
                <el-tag
                  v-if="roleListDto[consult.consultObject]"
                  :type="roleListDto[consult.consultObject].type"
                  size="mini"
                >
                  {{ roleListDto[consult.consultObject].name }}
                </el-tag>
                {{ consult.consultTitle }}
              </div>
              <p class="time">
                {{ dayjs(consult.consultTime).format('YYYY-MM-DD HH:mm:ss') }}
              </p>
            </div>
            <p class="description">
              {{ consult.consultDetail }}
            </p>
            <div>
              <el-image
                v-for="image in consult.imageUrlsList"
                :key="image"
                fit="contain"
                :src="image"
                :preview-src-list="consult.imageUrlsList"
                style="width: 100px; height: 100px;margin-right: 10px"
              />
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: '',
  components: {},
  props: {
    consults: {
      type: Array,
      default: () => [],
    },
    proofList: {
      type: Array,
      default: () => [],
    },
    refundInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tabActive: '1',
      roleListDto: {
        1: { name: '用户', type: 'warning' },
        2: { name: '商家', type: 'success' },
        3: { name: '平台', type: '' },
        4: { name: '系统', type: 'danger' },
      },
    };
  },
  computed: {
    list() {
      return this.tabActive === '1' ? this.consults : this.proofList;
    },
  },
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    dayjs,
    tabClick() {
      this.$emit('tabClick', this.tabActive);
    },
  },
};
</script>

<style lang="scss">
.refund-history-tab {
  .el-tabs__nav {
    padding-top: 10px;
    height: 50px;
  }
}
</style>
<style scoped lang="scss">
.consult {
  width: 100%;
  display: flex;
  padding: 10px;
  .content_heaer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .icon {
    margin-top: 8px;
    width: 50px;
    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
  .content {
    flex: 1;
    margin-left: 10px;
    .description {
      color: #909399;
      line-height: 22px;
      white-space: pre-line;
      word-break: break-all;
    }
  }
  .time {
    width: 150px;
    font-size: 14px;
    line-height: 30px;
  }
}
.consult:not(:last-child) {
  border-bottom: 1px solid #e6ebf5;
}
.consult::after {
  content: '';
  display: block;
  clear: both;
}
</style>
