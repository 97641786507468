<template>
  <div class="">
    <!-- 介入状态 -->
    <el-card
      class="refund-card"
      shadow="always"
    >
      <div
        slot="header"
        class="header"
      >
        <span>介入状态
          <span style="opacity: 0;">{{ refundInfo.interveneStatus }}</span>
        </span>
      </div>
      <div class="content">
        <div class="content_header">
          <div class="content_header_laft">
            <!-- 介入状态名字 -->
            <p class="content_header_laft_name">
              {{ refundInfo.interveneStatusName }}
            </p>

            <template v-if="[4, 14].includes(+refundInfo.refundStatus)">
              <!-- 剩余时间&操作时间 -->
              <TimeView
                v-if="(refundInfo.interveneInfo.replenishSupplier === 1 && appName === 'gys')
                  || refundInfo.interveneStatus === 1"
                :app-name="appName"
                :refund-info="refundInfo"
                @getRefundDetail="$emit('getRefundDetail')"
                @getConsult="getConsult"
                @getProofList="getProofList"
              />
              <span v-if="refundInfo.interveneInfo.replenishSupplier === -1 && appName === 'gys'">
                （举证超时）
              </span>
            </template>
          </div>
          <!-- 介入按钮 -->
          <div class="header_btn">
            <el-button
              v-if="appName === 'gys'
                && (+refundInfo.interveneStatus === 1 || +refundInfo.interveneStatus === 2)"
              type="primary"
              size="mini"
              @click="showProofUploading = true"
            >
              上传举证
            </el-button>
          </div>
        </div>

        <!-- 待商家、买家举证 -->
        <div
          v-if="appName === 'admin'"
          class="content_money"
          style="margin-right: 10px"
        >
          <div
            v-if="refundInfo.interveneInfo.replenishSupplier"
            style="width: 100%"
          >
            待商家举证中
            <!-- 剩余时间&操作时间 -->
            <TimeView
              v-if="refundInfo.interveneInfo.replenishSupplier === 1"
              identity="supplier"
              :app-name="appName"
              :refund-info="refundInfo"
              @getRefundDetail="$emit('getRefundDetail')"
              @getConsult="getConsult"
              @getProofList="getProofList"
            />
            <span v-if="refundInfo.interveneInfo.replenishSupplier === -1">（已超时）</span>
          </div>
          <div
            v-if="refundInfo.interveneInfo.replenishConsumer"
            style="width: 100%"
          >
            待买家举证中
            <!-- 剩余时间&操作时间 -->
            <TimeView
              v-if="refundInfo.interveneInfo.replenishConsumer === 1"
              identity="user"
              :app-name="appName"
              :refund-info="refundInfo"
              @getRefundDetail="$emit('getRefundDetail')"
              @getConsult="getConsult"
              @getProofList="getProofList"
            />
            <span v-if="refundInfo.interveneInfo.replenishConsumer === -1">（已超时）</span>
          </div>
        </div>

        <!-- 补充举证提示 -->
        <div
          v-if="appName === 'gys' && refundInfo.interveneInfo.replenishSupplier === 1"
          class="content_money"
          style="color: red"
        >
          请补充举证：{{ refundInfo.interveneInfo.replenishTypeSupplierName }}
        </div>
      </div>
    </el-card>

    <!-- 上传举证 -->
    <ProofUploading
      :refund-info="refundInfo"
      :show.sync="showProofUploading"
      @onSubmit="onSubmitProofUploading"
    />
  </div>
</template>

<script>
import TimeView from './timeView.vue';
import ProofUploading from './proofUploading.vue';

export default {
  name: '',
  components: {
    TimeView,
    ProofUploading,
  },
  props: {
    refundInfo: {
      type: Object,
      default: () => {},
    },
    appName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showProofUploading: false, // 显示上传举证
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    getConsult() {
      this.$emit('getConsult');
    },
    getProofList() {
      this.$emit('getProofList');
    },
    // 提交上传举证
    onSubmitProofUploading(item) {
      this.$axios.post(this.$api.refund.interveneAddEvidence, {
        ...item,
        refundId: this.refundInfo.refundId,
        interveneId: this.refundInfo.interveneInfo.interveneId,
      }).then((row) => {
        if (row.code === 0) {
          this.$message.success('操作成功！');
          this.$emit('getRefundDetail');
          this.showProofUploading = false;
          // 刷新
          this.getConsult();
          this.getProofList();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
