var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "el-card",
        { staticClass: "refund-card", attrs: { shadow: "always" } },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.tabActive,
                    callback: function ($$v) {
                      _vm.tabActive = $$v
                    },
                    expression: "tabActive",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "售后申请", name: "1" } },
                    [
                      _c("p", [
                        _c("span", [_vm._v("售后编码:")]),
                        _c("span", [_vm._v(_vm._s(_vm.refundInfo.refundNo))]),
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("售后类型:")]),
                        _c("span", { staticClass: "red" }, [
                          _vm._v(
                            _vm._s(
                              _vm.REFUND_TYPE_MAP[_vm.refundInfo.refundType]
                            )
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("订单状态:")]),
                        _c("span", { staticClass: "red" }, [
                          _vm._v(_vm._s(_vm.refundInfo.refundStatusName)),
                        ]),
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("退款金额:")]),
                        _c("span", { staticClass: "red" }, [
                          _vm._v("￥" + _vm._s(_vm.refundInfo.applyRefundFee)),
                        ]),
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("退款原因:")]),
                        _c("span", { staticClass: "red" }, [
                          _vm._v(_vm._s(_vm.refundInfo.reasonName)),
                        ]),
                      ]),
                      _vm.refundInfo.reasonDescription
                        ? _c("p", [
                            _c("span", [_vm._v("退款补充原因:")]),
                            _c("span", { staticClass: "word_break" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.refundInfo.reasonDescription || "暂无"
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm.refundInfo.interveneInfo
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "介入申请", name: "2" } },
                        [
                          _c("InterveneApply", {
                            attrs: { value: _vm.refundInfo },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "p",
                [
                  _c("span", { staticClass: "order_number" }, [
                    _vm._v("订单编号:"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.refundInfo.orderNo))]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.handleClipboard($event)
                        },
                      },
                    },
                    [_vm._v(" 复制 ")]
                  ),
                ],
                1
              ),
              _vm.refundInfo.groupTime
                ? _c("p", [
                    _c("span", [_vm._v("成团时间:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm
                            .dayjs(_vm.refundInfo.groupTime)
                            .format("YYYY-MM-DD HH:mm:ss")
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("p", [
                _c("span", [_vm._v("订单确认时间:")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm
                        .dayjs(_vm.refundInfo.orderCreateTime)
                        .format("YYYY-MM-DD HH:mm:ss")
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "good card" }, [
                _c("img", { attrs: { src: _vm.refundInfo.goodsImage } }),
                _c("div", { staticClass: "text" }, [
                  _c("p", [_vm._v(_vm._s(_vm.refundInfo.goodsName))]),
                  _c("p", [_vm._v("规格：" + _vm._s(_vm.refundInfo.skuName))]),
                  _vm.refundInfo.totalFee
                    ? _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.refundInfo.totalFee) + "元优惠券 "
                        ),
                      ])
                    : _vm._e(),
                  _c("p", [
                    _vm._v("共" + _vm._s(_vm.refundInfo.sellCount) + "件商品"),
                  ]),
                  _c("p", [
                    _vm.refundInfo.membershipCardFee > 0
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.refundInfo?.memberTypeExplain) +
                              "支付 :￥" +
                              _vm._s(_vm.refundInfo.membershipCardFee) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(
                      " 实收:￥" +
                        _vm._s(_vm.refundInfo.supplierPayment) +
                        "(邮费:" +
                        _vm._s(_vm.refundInfo.postFee) +
                        ") "
                    ),
                  ]),
                  _c("p", { staticClass: "goods_payment" }, [
                    _vm._v(" 金额：￥" + _vm._s(_vm.refundInfo.payment) + " "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card" }, [
                _c("i", { staticClass: "el-icon-location icon" }),
                _c("div", { staticClass: "text" }, [
                  _c("p", [
                    _c("span", [_vm._v("买家：")]),
                    _c("span", [_vm._v(_vm._s(_vm.refundInfo.receiverName))]),
                  ]),
                  _c("p", [
                    _c("span", [_vm._v("联系电话")]),
                    _c("span", [_vm._v(_vm._s(_vm.refundInfo.mobile))]),
                  ]),
                  _c("p", [
                    _c("span", [_vm._v("收货地址：")]),
                    _c("span", [_vm._v(_vm._s(_vm.refundInfo.address))]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }